import { useEffect, useRef } from 'react'

import { useStore } from 'hooks/use-store'
import { store } from 'store/store'
import { searchActions } from 'store/notes/search-slice'

import { Button } from 'elements/button/Button'
import { Input } from 'elements/input/Input'

import cross from '../media/cross.svg'
import magnifying_glass from '../media/magnifying_glass.svg'

import './SearchBar.scss'

export const SearchBar = () => {
    const query = useStore((store) => store.search)

    const setQuery = (query: string | null) => {
        store.dispatch(searchActions.setSearch(query))
    }

    const toggleSearchOpen = () => {
        setQuery(query === null ? '' : null)
    }

    const inputRef = useRef<HTMLInputElement>(null)

    const closeSearch = () => setQuery(null)

    useEffect(() => {
        if (!inputRef.current) return

        if (query === '') {
            inputRef.current.focus()
        }
    }, [query])

    return (
        <div className='SearchBar'>
            {query !== null && (
                <>
                    <img
                        src={magnifying_glass}
                        className='search-icon'
                        alt=''
                    />
                    <Input
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        ref={inputRef}
                    />
                    <Button className='close' onClick={closeSearch}>
                        <img src={cross} alt='Close search' />
                    </Button>
                </>
            )}
            {query === null && (
                <Button className='secondary' onClick={toggleSearchOpen}>
                    <img src={magnifying_glass} alt='Search' />
                </Button>
            )}
        </div>
    )
}
