import * as TD from 'types/types'
import * as API from 'types/api'

import { useEffect, useState } from 'react'

import { store } from 'store/store'
import { notesActions } from 'store/notes/notes-slice'
import { notificationsActions } from 'store/notifications/notifications-slice'

import { NoteInput } from './elements/NoteInput'
import { Button } from 'elements/button/Button'

import { request } from 'sys/request'
import { merge } from 'sys/merge'
import { cancelThrottle, throttle } from 'sys/throttle'
import { cancelDebounce } from 'sys/debounce'

import './ConflictEditor.scss'

export const ConflictEditor = ({ conflict }: { conflict: TD.Conflict }) => {
    const [noteText, setNoteText] = useState(() => {
        const draft = store
            .getState()
            .notes.drafts.find(({ id }) => id === conflict.id)!
        const note = store
            .getState()
            .notes.notes.find(({ id }) => id === conflict.id)!

        const diff = merge(conflict.text, note.text, draft.text)

        return diff.text
    })

    useEffect(() => {
        store.dispatch(
            notificationsActions.addNotification({
                type: 'warning',
                text: 'Note history diverges from server.\nReview and resolve conflicts to save.',
            })
        )
    }, [])

    const setText = (newText: string) => {
        setNoteText(newText)
    }

    const done = () => {
        // TODO: move to thunk
        store.dispatch(
            notesActions.updateDraft({ id: conflict.id, text: noteText })
        )
        store.dispatch(notesActions.removeConflict(conflict.id))

        cancelDebounce(conflict.id)
        cancelThrottle(conflict.id)

        throttle(conflict.id, () =>
            request({
                url: `/api/notes/${conflict.id}/`,
                method: 'PATCH',
                body: {
                    text: noteText,
                    last_updated_at: conflict.last_updated_at,
                },
                success: (serverNote: API.ExistingNote) => {
                    store.dispatch(notesActions.updateNote(serverNote))
                },
            })
        )
    }

    return (
        <div className='ConflictEditor'>
            <NoteInput text={noteText} setText={setText} />
            <Button className='primary' onClick={done}>
                Resolve
            </Button>
        </div>
    )
}
