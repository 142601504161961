import * as TD from 'types/types'
import * as API from 'types/api'

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { request, HTTP_409_CONFLICT } from 'sys/request'
import { cancelDebounce, debounce } from 'sys/debounce'
import { cancelThrottle, throttle } from 'sys/throttle'

import { notesActions } from 'store/notes/notes-slice'

export const getNotes =
    (url: string) =>
    (
        dispatch: ThunkDispatch<TD.Store, undefined, AnyAction>,
        getState: () => TD.Store
    ) => {
        request({
            url,
            success: (data: API.PaginatedContext<API.Note>) => {
                // don't undermine drafts in progress
                const drafts = getState().notes.drafts
                const newNotes = data.results.filter(
                    ({ id }) => !drafts.some((draft) => draft.id === id)
                )

                dispatch(notesActions.addNotesFromAPI(newNotes))

                if (data.remaining === 0) {
                    dispatch(notesActions.setStatus('READY'))
                } else {
                    dispatch(notesActions.setStatus('LOADING'))

                    dispatch(getNotes(data.next))
                }
            },
            networkError: (err) => {
                dispatch(notesActions.setStatus('ERROR'))
            },
            requestError: () => {
                dispatch(notesActions.setStatus('ERROR'))
            },
        })
    }

const postNote =
    (newNote: TD.Draft, options?: { keepalive: boolean }) =>
    (dispatch: ThunkDispatch<TD.Store, undefined, AnyAction>) => {
        return request({
            url: `/api/notes/`,
            method: 'POST',
            keepalive: options?.keepalive,
            body: newNote,
            success: (serverNote: API.ExistingNote) => {
                dispatch(notesActions.updateNote(serverNote))
            },
        })
    }

const patchNote =
    (newNote: TD.Draft, oldNote: TD.Note, options?: { keepalive: boolean }) =>
    (dispatch: ThunkDispatch<TD.Store, undefined, AnyAction>) => {
        return request({
            url: `/api/notes/${newNote.id}/`,
            method: 'PATCH',
            keepalive: options?.keepalive,
            body: {
                text: newNote.text,
                last_updated_at: oldNote.last_updated_at,
            },
            success: (serverNote: API.ExistingNote) => {
                dispatch(notesActions.updateNote(serverNote))
            },
            requestError: (response, preventDefaultNotification) => {
                if (response.status === HTTP_409_CONFLICT) {
                    preventDefaultNotification()
                    response
                        .json()
                        .then((conflictingNote: API.ConflictingNote) => {
                            dispatch(notesActions.setConflict(conflictingNote))
                        })
                }
            },
        })
    }

export const pushNote =
    (newNote: TD.Draft, debounceDelay = 0, options?: { keepalive: boolean }) =>
    (
        dispatch: ThunkDispatch<TD.Store, undefined, AnyAction>,
        getState: () => TD.Store
    ) => {
        debounce(newNote.id, debounceDelay, () => {
            throttle(newNote.id, () => {
                const oldNote = getState().notes.notes.find(
                    ({ id }) => id === newNote.id
                )

                if (oldNote !== undefined) {
                    if (newNote.text !== oldNote.text) {
                        return dispatch(patchNote(newNote, oldNote, options))
                    } else {
                        // No change, draft matches saved note
                        dispatch(notesActions.removeDraft(newNote.id))
                        return Promise.resolve()
                    }
                } else {
                    return dispatch(postNote(newNote, options))
                }
            })
        })
    }

export const deleteNote =
    (noteId: string) =>
    (
        dispatch: ThunkDispatch<TD.Store, undefined, AnyAction>,
        getState: () => TD.Store
    ) => {
        cancelDebounce(noteId)
        cancelThrottle(noteId)

        const noteExists = getState().notes.notes.some(
            ({ id }) => id === noteId
        )

        if (noteExists) {
            request({
                url: `/api/notes/${noteId}/`,
                method: 'DELETE',
                success: () => {
                    dispatch(notesActions.removeNote(noteId))
                },
            })
        } else {
            dispatch(notesActions.removeNote(noteId))
        }
    }
