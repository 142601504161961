import { Link } from 'react-router-dom'

import logo_icon from 'app/notes/media/logo_icon.svg'

import './Logo.scss'

export const Logo = () => {
    return (
        <Link to='/notes' className='Logo'>
            <img src={logo_icon} alt='' />
            <div className='logo-title'>Flatnotes</div>
        </Link>
    )
}
