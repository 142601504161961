import { store } from 'store/store'
import { logout } from 'store/auth/auth-actions'
import { notificationsActions } from 'store/notifications/notifications-slice'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.flatnotes.app'

const HTTP_401_UNAUTHORIZED = 401
export const HTTP_409_CONFLICT = 409

const requestErrorNotification = (res: Response) => {
    store.dispatch(
        notificationsActions.addNotification({
            type: 'warning',
            text: 'Server could not process request.',
        })
    )

    res.json()
        .then((data) => {
            console.warn(data)
        })
        .catch((err) => {
            console.warn(res, err)
        })
}

const networkErrorNotification = (err: any) => {
    store.dispatch(
        notificationsActions.addNotification({
            type: 'warning',
            text: 'Could not connect to server.',
        })
    )

    console.error(err)
}

export const request = <T>({
    url,
    method = 'GET',
    body,
    isAuthenticated = true,
    keepalive = false,
    success,
    requestError,
    networkError,
}: (
    | {
          method?: 'GET' | 'HEAD' | 'DELETE'
          body?: undefined
      }
    | {
          method: 'POST' | 'PUT'
          body: T
      }
    | {
          method: 'PATCH'
          body: Partial<T>
      }
) & {
    url: string
    isAuthenticated?: boolean
    keepalive?: boolean
    success: (data: any) => void
    requestError?: (
        res: Response,
        preventDefaultNotification: () => void
    ) => void
    networkError?: (err: any) => void
}) => {
    const token = store.getState().auth.token

    const headers = {
        ...(isAuthenticated && token && { Authorization: 'Token ' + token }),
        'Content-Type': 'application/json',
    }

    const isUsingOwnApi = url.startsWith('/')
    const fullUrl = isUsingOwnApi ? `${API_URL}${url}` : url
    if (!fullUrl.startsWith('http')) {
        console.warn(`URL does not start with http: "${url}"`)
    }

    return fetch(fullUrl, {
        method,
        headers,
        keepalive,
        ...(body && { body: JSON.stringify(body) }),
    })
        .catch((err) => {
            // network connection issue
            if (networkError) networkError(err)
            networkErrorNotification(err)
        })
        .then((res) => {
            if (!res) return
            if (res.ok) {
                if (res.statusText === 'No Content') {
                    return ''
                } else {
                    return res.json()
                }
            } else {
                // received error from server
                if (isUsingOwnApi && res.status === HTTP_401_UNAUTHORIZED) {
                    logout()
                    store.dispatch(
                        notificationsActions.addNotification({
                            type: 'warning',
                            text: 'Session has expired. Please log in to continue.',
                        })
                    )
                    return
                }

                let notificationPrevented = false
                const preventDefaultNotification = () =>
                    (notificationPrevented = true)
                if (requestError)
                    requestError(res.clone(), preventDefaultNotification)
                if (!notificationPrevented) requestErrorNotification(res)
            }
        })
        .then((data) => {
            if (data !== undefined) {
                success(data)
            }
        })
}
