import { Component } from 'react'

export class DismountListener extends Component<{ onDismount: () => void }> {
    render() {
        return this.props.children
    }

    componentWillUnmount() {
        this.props.onDismount()
    }
}
