import * as TD from 'types/types'

import { AnyAction } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { saveDrafts, saveNotes } from 'sys/localStorage'

export const persistDraftsMiddleware =
    ({ getState }: { getState: () => TD.Store }) =>
    (next: Dispatch<AnyAction>) =>
    (action: AnyAction) => {
        const previousState = getState()
        const result = next(action)
        const finalState = getState()

        if (previousState.notes.drafts !== finalState.notes.drafts) {
            saveDrafts(finalState.notes.drafts)
        }

        return result
    }

export const persistNotesMiddleware =
    ({ getState }: { getState: () => TD.Store }) =>
    (next: Dispatch<AnyAction>) =>
    (action: AnyAction) => {
        const previousState = getState()
        const result = next(action)
        const finalState = getState()

        if (previousState.notes.notes !== finalState.notes.notes) {
            saveNotes(finalState.notes.notes, finalState.notes.last_pull)
        }

        return result
    }
