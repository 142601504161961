import * as API from 'types/api'

import './FormErrors.scss'

export const FormErrors = ({ errors }: { errors?: API.ErrorResponse }) => {
    if (!errors) return null

    if (typeof errors === 'object') {
        const errorArr = Object.keys(errors).map((key) => {
            if (key === 'non_field_errors') {
                return errors[key].join(', ')
            }
            return `${key}: ${errors[key].join(', ')}`
        })
        return (
            <div className='FormErrors'>
                {errorArr.map((err, i) => (
                    <div key={i}>{err}</div>
                ))}
            </div>
        )
    }

    return <div className='FormErrors'>{errors}</div>
}
