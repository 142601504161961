import * as API from 'types/api'

import { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { request } from 'sys/request'

import { setToken } from 'store/auth/auth-actions'

import { Button } from 'elements/button/Button'
import { Input } from 'elements/input/Input'
import { FormErrors } from 'elements/form/FormErrors'

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<API.ErrorResponse>()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const next = searchParams.get('next') ?? '/'

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        request({
            url: '/api/auth/login/',
            method: 'POST',
            isAuthenticated: false,
            body: { email, password },
            success: (data) => {
                setToken(data.token)
                navigate(next)
            },
            requestError: (res) =>
                res
                    .json()
                    .then((err: API.DjangoRestFrameworkError) => setError(err)),
            networkError: (err) => setError(String(err)),
        })
    }

    return (
        <div className='Login'>
            <h2>Log in</h2>
            <div className='subheading'>
                Welcome back! Please enter your details.
            </div>

            <form onSubmit={handleSubmit}>
                <label htmlFor='email'>Email</label>
                <Input
                    id='email'
                    name='email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor='password'>Password</label>
                <Input
                    id='password'
                    name='password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <FormErrors errors={error} />

                <div className='forgot'>
                    <Link to='/auth/forgot-password'>Forgot password</Link>
                </div>

                <Button type='submit'>Log in</Button>

                <div className='sign-up'>
                    Don't have an account?{' '}
                    <Link to='/auth/sign-up'>Sign up</Link>
                </div>
            </form>
        </div>
    )
}
