import * as TD from 'types/types'

import { Link } from 'react-router-dom'

import { useStore } from 'hooks/use-store'
import { useCreateNote } from 'hooks/use-create-note'

import { Error } from 'elements/error/Error'
import { Loading } from 'elements/loading/Loading'

import './NotesList.scss'

const filterNoteBySearch = ({ text }: TD.Note | TD.Draft, search: string) => {
    const lowerCaseText = text.toLowerCase()
    return search
        .toLowerCase()
        .split(' ')
        .every((word) => lowerCaseText.includes(word))
}

const Note = ({
    note,
    type,
}: {
    note: TD.Note | TD.Draft
    type: 'note' | 'draft' | 'conflict'
}) => {
    const lines = note.text.split('\n')
    const title = lines[0].startsWith('# ')
        ? lines.shift()?.replace(/^# /, '')
        : undefined

    let preview: string[] = []
    let additionalLineCount = 0

    while (lines.length > 0) {
        if (preview.length >= 3) {
            additionalLineCount = lines.length
            break
        }
        const thisLine = lines.shift() as string
        if (thisLine === '') continue
        preview.push(thisLine)
    }

    const date =
        'last_updated_at' in note ? new Date(note.last_updated_at) : new Date()
    const timestamp = date.toLocaleString()

    return (
        <Link className='Note' to={note.id}>
            <div className='timestamp'>
                {timestamp}
                {type === 'conflict' && '!!!'}
                {type === 'draft' && '*'}
            </div>
            {title && <div className='title'>{title}</div>}
            {preview.length > 0 && (
                <>
                    <div className='preview'>{preview.join('\n')}</div>
                    {additionalLineCount > 0 ? (
                        <div className='line-count'>
                            {`(+${additionalLineCount} lines)`}
                        </div>
                    ) : (
                        ''
                    )}
                </>
            )}
        </Link>
    )
}

const NoNotes = () => {
    const createNote = useCreateNote()

    return (
        <div className='NoNotes'>
            <div>Looks like you don't have any notes.</div>
            <div>Add one now to get started.</div>
            <button className='Button primary' onClick={createNote}>
                New note
            </button>
        </div>
    )
}

export const NotesList = () => {
    const notesResult = useStore(({ notes }) => notes)
    const query = useStore((store) => store.search)

    const isEmpty =
        notesResult.notes.length === 0 && notesResult.drafts.length === 0

    return (
        <div className='NotesList'>
            {notesResult.status === 'ERROR' && <Error />}
            <div className='notes'>
                {notesResult.conflicts.map((conflict) => (
                    <Note key={conflict.id} note={conflict} type='conflict' />
                ))}
                {notesResult.drafts
                    .filter(
                        (draft) =>
                            !notesResult.conflicts.some(
                                (conflict) => conflict.id === draft.id
                            )
                    )
                    .map((draft) => {
                        return query === null ||
                            query.length === 0 ||
                            filterNoteBySearch(draft, query) ? (
                            <Note key={draft.id} note={draft} type='draft' />
                        ) : null
                    })}
                {notesResult.notes
                    .filter(
                        (note) =>
                            !notesResult.conflicts.some(
                                (conflict) => conflict.id === note.id
                            ) &&
                            !notesResult.drafts.some(
                                (draft) => draft.id === note.id
                            )
                    )
                    .map((note) => {
                        return query === null ||
                            query.length === 0 ||
                            filterNoteBySearch(note, query) ? (
                            <Note key={note.id} note={note} type='note' />
                        ) : null
                    })}
                {isEmpty && <NoNotes />}
            </div>
            {notesResult.status === 'LOADING' && <Loading />}
        </div>
    )
}
