const debounceRegistry: { [id: string]: number | undefined } = {}

export const cancelDebounce = (id: string) => {
    clearTimeout(debounceRegistry[id])
    delete debounceRegistry[id]
}

export const debounce = (id: string, delay: number, onTimeout: () => void) => {
    if (debounceRegistry[id]) {
        cancelDebounce(id)
    }

    const newTimeoutId = window.setTimeout(() => {
        cancelDebounce(id)
        onTimeout()
    }, delay)

    debounceRegistry[id] = newTimeoutId
}
