import { useCreateNote } from 'hooks/use-create-note'

import { Button } from 'elements/button/Button'
import { Logo } from 'elements/logo/Logo'

import { HeaderMenu } from './HeaderMenu'
import { SearchBar } from './SearchBar'

import './NotesHeader.scss'

export const NotesHeader = () => {
    const createNote = useCreateNote()

    return (
        <div className='NotesHeader'>
            <Logo />

            <SearchBar />

            <HeaderMenu />

            <Button className='primary desktop-only' onClick={createNote}>
                New Note
            </Button>
        </div>
    )
}
