import * as TD from 'types/types'

import { useState, useEffect } from 'react'

import { DismountListener } from 'sys/DismountListener'

import { store } from 'store/store'
import { notesActions } from 'store/notes/notes-slice'
import { pushNote, deleteNote } from 'store/notes/notes-thunks'

import { NoteInput } from './elements/NoteInput'

import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_TEXT } from 'sys/consts'

import './NoteEditor.scss'

export const NoteEditor = ({ note }: { note: TD.Draft }) => {
    const [noteText, setNoteText] = useState(note.text)

    useEffect(() => {
        const handleVisibilityChange = (e: any) => {
            if (document.visibilityState !== 'visible') {
                // NOTE:
                // 'keepalive' not supported on Firefox, so this will only fire on tab change.
                // Chrome does not send this if entire browser closed.
                // Consider adding a beforeunload event, if unsaved, as well?
                store.dispatch(
                    pushNote({ id: note.id, text: noteText }, 0, {
                        keepalive: true,
                    })
                )
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () =>
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
    }, [note.id, noteText])

    const setText = (newText: string) => {
        setNoteText(newText)

        store.dispatch(
            notesActions.updateDraft({
                id: note.id,
                text: newText,
            })
        )

        // NOTE: is this too slow to run every character, if text is large?
        const numberOfLines = newText.split('\n').length
        if (numberOfLines === noteText.split('\n').length) {
            store.dispatch(
                pushNote({ id: note.id, text: newText }, DEFAULT_DEBOUNCE_DELAY)
            )
        } else {
            // submit right away
            store.dispatch(pushNote({ id: note.id, text: newText }))
        }
    }

    const done = () => {
        if (!store.getState().auth.token) return // already logged out
        if (store.getState().notes.conflicts.find(({ id }) => id === note.id))
            return // conflicting, don't sync

        if (noteText === '' || noteText === DEFAULT_TEXT) {
            store.dispatch(deleteNote(note.id))
        } else {
            store.dispatch(pushNote({ id: note.id, text: noteText }))
        }
    }

    return (
        <DismountListener onDismount={done}>
            <div className='NoteEditor'>
                <NoteInput text={noteText} setText={setText} />
            </div>
        </DismountListener>
    )
}
