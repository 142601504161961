import { useNavigate } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import { store } from 'store/store'
import { notesActions } from 'store/notes/notes-slice'

import { DEFAULT_TEXT } from 'sys/consts'

export const useCreateNote = () => {
    const navigate = useNavigate()

    return () => {
        const id = uuid()

        store.dispatch(
            notesActions.addDraft({
                id,
                text: DEFAULT_TEXT,
            })
        )

        navigate(`/notes/${id}`)
    }
}
