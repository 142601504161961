import { Outlet, Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'

import { store } from 'store/store'
import { pushNote, getNotes } from 'store/notes/notes-thunks'

import { NotFound } from 'elements/not-found/NotFound'

import { NotesList } from './NotesList'
import { NoteEdit } from './NoteEdit'
import { NotesHeader } from './headers/NotesHeader'

import './Notes.scss'

const Notes = () => {
    useEffect(() => {
        store.getState().notes.drafts.forEach((draft) => {
            // sync pending drafts
            store.dispatch(pushNote(draft))
        })

        const last_pull = store.getState().notes.last_pull

        if (last_pull === null) {
            store.dispatch(getNotes('/api/notes/'))
        } else {
            store.dispatch(getNotes(`/api/notes/?since=${last_pull}`))
        }
    }, [])

    return (
        <>
            <NotesHeader />
            <div className='Notes'>
                <NotesList />
                <Outlet />
            </div>
        </>
    )
}

const NoNote = () => {
    return <></>
}

export const NotesRoutes = () => {
    return (
        <Routes>
            <Route element={<Notes />}>
                <Route index element={<NoNote />} />
                <Route path=':noteId' element={<NoteEdit />} />
            </Route>
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}
