import * as API from 'types/api'

import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { request } from 'sys/request'

import { store } from 'store/store'
import { notificationsActions } from 'store/notifications/notifications-slice'

import { Button } from 'elements/button/Button'
import { Input } from 'elements/input/Input'
import { FormErrors } from 'elements/form/FormErrors'

export const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [error, setError] = useState<API.ErrorResponse>()
    const { token, userId } = useParams()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        request({
            url: '/api/auth/reset-password/',
            method: 'POST',
            isAuthenticated: false,
            body: {
                token,
                user_id: userId,
                password,
            },
            success: () => {
                window.location.pathname = ''
                store.dispatch(
                    notificationsActions.addNotification({
                        type: 'success',
                        text: 'Your password has been reset.',
                    })
                )
            },
            requestError: (res) =>
                res
                    .json()
                    .then((err: API.DjangoRestFrameworkError) => setError(err)),
            networkError: (err) => setError(String(err)),
        })
    }

    return (
        <div className='ResetPassword'>
            <h2>Password reset</h2>
            <div className='subheading'>Please enter your new password.</div>

            <form onSubmit={handleSubmit}>
                <label htmlFor='password'>Password</label>
                <Input
                    id='password'
                    name='password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <FormErrors errors={error} />

                <Button type='submit'>Change password</Button>
            </form>
        </div>
    )
}
