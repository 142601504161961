const throttledActionRegistry: {
    [id: string]:
        | {
              status: 'PENDING'
              nextAction?: () => Promise<void>
          }
        | undefined
} = {}

export const cancelThrottle = (id: string) => {
    delete throttledActionRegistry[id]
}

export const throttle = (id: string, action: () => Promise<void>) => {
    if (throttledActionRegistry[id]) {
        // pending response, enqueue next request
        throttledActionRegistry[id] = {
            status: 'PENDING',
            nextAction: action,
        }
    } else {
        // no pending response, fire request immediately and set pending
        throttledActionRegistry[id] = {
            status: 'PENDING',
        }
        action().finally(() => {
            pendingActionDone(id)
        })
    }
}

const pendingActionDone = (id: string) => {
    const register = throttledActionRegistry[id]
    if (register?.nextAction) {
        // fire next request, clear next
        register.nextAction().then(() => {
            pendingActionDone(id)
        })
        delete register.nextAction
    } else {
        // clear status
        delete throttledActionRegistry[id]
    }
}
