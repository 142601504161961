import * as TD from 'types/types'

export const loadAuthData = () => {
    try {
        const data = localStorage.getItem('authData')
        return !data ? { token: null } : (JSON.parse(data) as TD.Auth)
    } catch (err) {
        return { token: null }
    }
}

export const saveAuthData = (authData: TD.Auth) => {
    try {
        localStorage.setItem('authData', JSON.stringify(authData))
    } catch (err) {
        // noop
    }
}

export const loadDrafts = () => {
    try {
        const drafts = localStorage.getItem('drafts')
        return !drafts
            ? { drafts: [] }
            : { drafts: JSON.parse(drafts) as TD.Draft[] }
    } catch (err) {
        return { drafts: [] }
    }
}

export const saveDrafts = (drafts: TD.Draft[]) => {
    try {
        localStorage.setItem('drafts', JSON.stringify(drafts))
    } catch (err) {
        // noop
    }
}

export const loadNotes: () => {
    notes: TD.Note[]
    last_pull: string | null
} = () => {
    try {
        const notes = localStorage.getItem('notes')
        const last_pull = localStorage.getItem('last_pull')
        return !notes || !last_pull
            ? { notes: [], last_pull: null }
            : {
                  notes: JSON.parse(notes),
                  last_pull: JSON.parse(last_pull),
              }
    } catch (err) {
        return { notes: [], last_pull: null }
    }
}

export const saveNotes = (notes: TD.Note[], last_pull: string | null) => {
    try {
        localStorage.setItem('notes', JSON.stringify(notes))
        localStorage.setItem('last_pull', JSON.stringify(last_pull))
    } catch (err) {
        // noop
    }
}
