import { useEffect, useRef } from 'react'

import './NoteInput.scss'

export const NoteInput = ({
    text,
    setText,
}: {
    text: string
    setText: (text: string) => void
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (!inputRef.current) return
        if (inputRef.current === document.activeElement) return // already focused

        const end = inputRef.current.value.length
        const start = inputRef.current.value === '# Title' ? 2 : end
        inputRef.current.setSelectionRange(start, end)
        inputRef.current.focus()
    }, [])

    return (
        <div className='NoteInput'>
            <textarea
                ref={inputRef}
                value={text}
                onChange={(event) => setText(event.target.value)}
            />
        </div>
    )
}
