import * as Dropdown from '@radix-ui/react-dropdown-menu'

import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { store } from 'store/store'
import { deleteNote } from 'store/notes/notes-thunks'
import { notesActions } from 'store/notes/notes-slice'
import { logout } from 'store/auth/auth-actions'

import { useCreateNote } from 'hooks/use-create-note'

import dots_horizontal from '../media/dots_horizontal.svg'
import file_copy from '../media/file_copy.svg'
import note_add from '../media/note_add.svg'
import exit from '../media/exit.svg'
import trash from '../media/trash.svg'

import './HeaderMenu.scss'

export const HeaderMenu = () => {
    const { noteId } = useParams()
    const navigate = useNavigate()
    const createNote = useCreateNote()

    const duplicateNote = () => {
        const newId = uuid()
        const notes = store.getState().notes

        let noteText = notes.drafts.find(({ id }) => id === noteId)?.text

        if (noteText === undefined) {
            noteText = notes.notes.find(({ id }) => id === noteId)?.text

            if (noteText === undefined) {
                throw new Error(`Could not find note ${noteId}`)
            }
        }

        store.dispatch(
            notesActions.addDraft({
                id: newId,
                text: noteText,
            })
        )

        navigate(`/notes/${newId}`)
    }

    const confirmDelete = () => {
        const confirmation = window.confirm('Delete note?')

        if (noteId && confirmation) {
            store.dispatch(deleteNote(noteId))

            navigate('..')
        }
    }

    const confirmLogout = () => {
        const confirmation = window.confirm('Are you sure you want to log out?')

        if (confirmation) {
            navigate('/')
            logout()
        }
    }

    return (
        <Dropdown.Root>
            <Dropdown.Trigger className='Button secondary'>
                <img src={dots_horizontal} alt='Options' />
            </Dropdown.Trigger>
            <Dropdown.Content className='HeaderMenu' align='start'>
                <Dropdown.Item onSelect={createNote} className='mobile-only'>
                    <img src={note_add} alt='' />
                    New Note
                </Dropdown.Item>
                <Dropdown.Separator className='mobile-only' />

                {noteId && (
                    <>
                        <Dropdown.Item onSelect={duplicateNote}>
                            <img src={file_copy} alt='' />
                            Duplicate Note
                        </Dropdown.Item>
                        <Dropdown.Separator />

                        <Dropdown.Item
                            onSelect={confirmDelete}
                            className='warning'
                        >
                            <img src={trash} alt='' />
                            Delete Note
                        </Dropdown.Item>
                        <Dropdown.Separator />
                    </>
                )}

                <Dropdown.Item onSelect={confirmLogout} className='warning'>
                    <img src={exit} alt='' />
                    Logout
                </Dropdown.Item>
            </Dropdown.Content>
        </Dropdown.Root>
    )
}
