import * as TD from 'types/types'
import * as API from 'types/api'

import { createSlice } from '@reduxjs/toolkit'
import { loadDrafts, loadNotes } from 'sys/localStorage'

const sortByLastUpdatedAt = (
    { last_updated_at: dateA }: TD.Note,
    { last_updated_at: dateB }: TD.Note
) => {
    if (dateA === null) return -1
    if (dateB === null) return 1
    if (dateA > dateB) return -1
    if (dateA < dateB) return 1
    return 0
}

const notesSlice = createSlice({
    name: 'notes',
    initialState: () => {
        const state: TD.Notes = {
            ...loadNotes(),
            ...loadDrafts(),
            conflicts: [],
            status: 'LOADING',
        }
        return state
    },
    reducers: {
        setStatus: (
            state,
            { payload: status }: { payload: TD.Notes['status'] }
        ) => {
            return {
                ...state,
                status,
            }
        },
        setConflict: (
            state,
            { payload: conflict }: { payload: TD.Conflict }
        ) => {
            return {
                ...state,
                conflicts: [
                    conflict,
                    ...state.conflicts.filter(({ id }) => id !== conflict.id),
                ],
            }
        },
        removeConflict: (state, { payload: noteId }: { payload: string }) => {
            return {
                ...state,
                conflicts: state.conflicts.filter(({ id }) => id !== noteId),
            }
        },
        addDraft: (state, { payload: newDraft }: { payload: TD.Draft }) => {
            return {
                ...state,
                drafts: [newDraft, ...state.drafts],
            }
        },
        updateDraft: (state, { payload: draft }: { payload: TD.Draft }) => {
            return {
                ...state,
                drafts: [
                    draft,
                    ...state.drafts.filter(({ id }) => id !== draft.id),
                ],
            }
        },
        removeDraft: (state, { payload: noteId }: { payload: string }) => {
            return {
                ...state,
                drafts: state.drafts.filter(({ id }) => id !== noteId),
            }
        },
        addNotesFromAPI: (
            state,
            { payload: newNotes }: { payload: API.Note[] }
        ) => {
            const newNoteIds = newNotes.map(({ id }) => id)

            const notes = [
                ...state.notes.filter(({ id }) => !newNoteIds.includes(id)),
                ...(newNotes.filter(
                    ({ text }) => text !== null // remove deleted notes
                ) as TD.Note[]),
            ].sort(sortByLastUpdatedAt)

            let last_pull = state.last_pull
            if (notes.length > 0 && state.drafts.length === 0) {
                last_pull = notes[0].last_updated_at
            }

            return {
                ...state,
                notes,
                last_pull,
            }
        },
        updateNote: (state, { payload: note }: { payload: TD.Note }) => {
            return {
                ...state,
                drafts: state.drafts.filter(
                    (draft) =>
                        !(draft.id === note.id && draft.text === note.text)
                ),
                notes: [
                    note,
                    ...state.notes.filter(({ id }) => id !== note.id),
                ],
            }
        },
        removeNote: (state, { payload: noteId }: { payload: string }) => {
            return {
                ...state,
                notes: state.notes.filter(({ id }) => id !== noteId),
                drafts: state.drafts.filter(({ id }) => id !== noteId),
            }
        },
    },
})

export const notesReducer = notesSlice.reducer
export const notesActions = notesSlice.actions
