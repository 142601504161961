import * as API from 'types/api'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from 'sys/request'

import { store } from 'store/store'
import { notificationsActions } from 'store/notifications/notifications-slice'

import { Button } from 'elements/button/Button'
import { Input } from 'elements/input/Input'
import { FormErrors } from 'elements/form/FormErrors'

export const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState<API.ErrorResponse>()

    const navigate = useNavigate()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        request({
            url: '/api/auth/forgot-password/',
            method: 'POST',
            isAuthenticated: false,
            body: { email },
            success: () => {
                navigate('/auth/login')
                store.dispatch(
                    notificationsActions.addNotification({
                        type: 'success',
                        text: 'Check your email for a password reset link.',
                    })
                )
            },
            requestError: (res) =>
                res
                    .json()
                    .then((err: API.DjangoRestFrameworkError) => setError(err)),
            networkError: (err) => setError(String(err)),
        })
    }

    return (
        <div className='ForgotPassword'>
            <h2>Forgot password</h2>
            <div className='subheading'>Enter the account's email address.</div>
            <form onSubmit={handleSubmit}>
                <label htmlFor='email'>Email</label>
                <Input
                    id='email'
                    name='email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <FormErrors errors={error} />

                <Button type='submit'>Request password change</Button>
            </form>
        </div>
    )
}
