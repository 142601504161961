import * as API from 'types/api'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from 'sys/request'

import { setToken } from 'store/auth/auth-actions'

import { Button } from 'elements/button/Button'
import { Input } from 'elements/input/Input'
import { FormErrors } from 'elements/form/FormErrors'

export const SignUp = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<API.ErrorResponse>()

    const navigate = useNavigate()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        request({
            url: '/api/auth/signup/',
            method: 'POST',
            isAuthenticated: false,
            body: { email, password },
            success: (data) => {
                setToken(data.token)
                navigate('/')
            },
            requestError: (res) =>
                res
                    .json()
                    .then((err: API.DjangoRestFrameworkError) => setError(err)),
            networkError: (err) => setError(String(err)),
        })
    }

    return (
        <div className='SignUp'>
            <h2>Sign up</h2>
            <div className='subheading'>
                Enter your information to create a new account.
            </div>
            <form onSubmit={handleSubmit}>
                <label htmlFor='email'>Email</label>
                <Input
                    id='email'
                    name='email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor='password'>Password</label>
                <Input
                    id='password'
                    name='password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <FormErrors errors={error} />

                <Button type='submit'>Sign up</Button>
            </form>
        </div>
    )
}
