import { Outlet, Route, Routes } from 'react-router-dom'

import { Logo } from 'elements/logo/Logo'
import { NotFound } from 'elements/not-found/NotFound'

import { Login } from './Login'
import { SignUp } from './SignUp'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'

import './Auth.scss'

const Auth = () => {
    return (
        <div className='Auth'>
            <div className='auth-header'>
                <Logo />
            </div>

            <div className='auth-content'>
                <Outlet />
            </div>
        </div>
    )
}

export const AuthRoutes = () => {
    return (
        <Routes>
            <Route element={<Auth />}>
                <Route path='login' element={<Login />} />
                <Route path='sign-up' element={<SignUp />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route
                    path='reset-password/:token/:userId'
                    element={<ResetPassword />}
                />
            </Route>

            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}
