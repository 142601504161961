import * as TD from 'types/types'

import { configureStore, combineReducers } from '@reduxjs/toolkit'

import { notificationsReducer } from './notifications/notifications-slice'
import { authReducer } from './auth/auth-slice'
import { notesReducer } from './notes/notes-slice'
import { searchReducer } from './notes/search-slice'

import { persistDraftsMiddleware, persistNotesMiddleware } from './middleware'

const appReducer = combineReducers({
    auth: authReducer,
    notifications: notificationsReducer,
    notes: notesReducer,
    search: searchReducer,
})

const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === 'CLEAR_ALL') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export const store = configureStore({
    reducer: (state: TD.Store | undefined, action) => {
        return rootReducer(state, action)
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(persistDraftsMiddleware)
            .concat(persistNotesMiddleware),
})
