import * as TD from 'types/types'
import * as API from 'types/api'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useStore } from 'hooks/use-store'

import { Loading } from 'elements/loading/Loading'
import { Error } from 'elements/error/Error'

import { NoteEditor } from './NoteEditor'
import { ConflictEditor } from './ConflictEditor'

const NoteNotFound = () => {
    return (
        <div className='NotFound'>
            <p>Unable to load note.</p>
        </div>
    )
}

export const NoteEdit = () => {
    const { noteId } = useParams()

    const [noteResult, setNoteResult] = useState<
        API.Result<
            | { note: TD.Note | TD.Draft; conflict: false }
            | { note: TD.Conflict; conflict: true }
        >
    >({
        status: 'LOADING',
    })

    const notes = useStore(({ notes }) => notes)

    useEffect(() => {
        const conflict = notes.conflicts.find(({ id }) => id === noteId)

        const note = // TODO - create drafts from notes?
            notes.drafts.find(({ id }) => id === noteId) ||
            notes.notes.find(({ id }) => id === noteId)

        if (conflict) {
            setNoteResult({
                status: 'READY',
                response: { note: conflict, conflict: true },
            })
        } else if (note) {
            setNoteResult({
                status: 'READY',
                response: { note, conflict: false },
            })
        } else if (notes.status === 'LOADING') {
            setNoteResult({ status: 'LOADING' })
        } else {
            setNoteResult({
                status: 'ERROR',
                error: new Response(null, {
                    status: 404,
                    statusText: 'Note not found',
                }),
            })
        }
    }, [noteId, notes])

    switch (noteResult.status) {
        case 'LOADING':
            return <Loading />
        case 'ERROR':
            if (
                noteResult.error instanceof window.Response &&
                noteResult.error.status === 404
            ) {
                return <NoteNotFound />
            }
            return <Error />
        case 'READY':
            return noteResult.response.conflict ? (
                <ConflictEditor
                    conflict={noteResult.response.note}
                    key={'conflict-' + noteResult.response.note.id}
                />
            ) : (
                <NoteEditor
                    note={noteResult.response.note}
                    key={noteResult.response.note.id}
                />
            )
    }
}
