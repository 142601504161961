import * as TD from 'types/types'

import { createSlice } from '@reduxjs/toolkit'

const searchSlice = createSlice({
    name: 'search',
    initialState: null as string | null,
    reducers: {
        setSearch: (
            state,
            { payload: newState }: { payload: TD.Store['search'] }
        ) => {
            return newState
        },
    },
})

export const searchReducer = searchSlice.reducer
export const searchActions = searchSlice.actions
