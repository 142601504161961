import diff3Merge from 'diff3'

const LINE = /^.*(\n|$)/gm

export const merge = (a: string, o: string, b: string) => {
    const a_lines = a.match(LINE)
    const o_lines = o.match(LINE)
    const b_lines = b.match(LINE)

    if (a_lines === null || o_lines === null || b_lines === null) {
        throw new Error('Could not process merge input')
    }

    const diff3_result = diff3Merge(a_lines, o_lines, b_lines)

    let output = ''
    let conflict = false
    for (const chunk of diff3_result) {
        if (chunk.ok) {
            output += chunk.ok.join('')
        }

        if (chunk.conflict) {
            conflict = true

            // NOTE: may "lose" newline at end of file if there is a conflict
            output += '<<<<<<<\n'
            output += chunk.conflict.a.join('')
            if (!output.endsWith('\n')) output += '\n'
            output += '=======\n'
            output += chunk.conflict.b.join('')
            if (!output.endsWith('\n')) output += '\n'
            output += '>>>>>>>\n'
        }
    }

    return { text: output, conflict }
}
